<template>
  <div>
    <b-card>
      <h4>ดาวน์โหลดข้อมูลการถอนแต่ละเดือน</h4>
      <hr />
      <b-overlay :show="loading" style="min-height: 100px;">
        <div v-if="cShowEmpty" class="d-flex align-items-center justify-content-center" style="height: 100px;">
          <p class="text-center text-muted m-0">ไม่พบข้อมูล</p>
        </div>
        <app-timeline>
          <app-timeline-item v-for="(item, idx) in listMonth" :key="idx">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>
                {{
                  $date(item)
                    .locale('th')
                    .format('MMMM-YYYY')
                }}
              </h6>
              <small class="text-muted">เดือน-ปี (ค.ศ.)</small>
            </div>
            <b-button
              v-b-toggle.report-list
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              size="sm"
              variant="outline-primary"
              @click="generateExcel(item)"
            >
              ดาวน์โหลด Excel
            </b-button>
          </app-timeline-item>
        </app-timeline>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      listMonth: [],
      loading: false,
    }
  },
  computed: {
    cShowEmpty() {
      return !this.loading && this.listMonth.length === 0
    },
  },
  mounted() {
    this.fetchAllWithdrawMonth()
  },
  methods: {
    async fetchAllWithdrawMonth() {
      this.loading = true

      const resp = await this.api.getV2('api/admin/partner-withdraw/report/query-approved-date').catch(() => null)

      if (resp?.code === 200) {
        this.listMonth = [...resp.data]
      } else {
        this.gDisplayToast('พบข้อผิดพลาด', 'ไม่สามารถแสดงข้อมูลได้ โปรดติดต่อผู้ดูแลระบบ', 'danger')
      }

      this.loading = false
    },
    async generateExcel(value) {
      this.loading = true

      const fileDate = this.$date(value).format('YYYY-MM')

      const resp = await this.api.getV2(`api/admin/partner-withdraw/report/export-approved-to-excel/${fileDate}`, {
        responseType: 'arraybuffer',
        data: {
          date: fileDate,
        },
      })
      const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

      const blob = new Blob([resp], { type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `withdraw-report-${fileDate}.xlsx`

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      this.loading = false
    },
  },
}
</script>

<style lang="scss"></style>
